export const frCommons = {
    'commons.empty': '-',
    'commons.yes': 'Oui',
    'commons.no': 'Non',
    'commons.or': 'Ou',
    'commons.ok': 'OK',

    //* ACTIONS
    'commons.action.validate': 'Valider',
    'commons.action.edit': 'Modifier',
    'commons.action.export': 'Export Excel',

    'commons.submission.shop': 'Magasin',
    'commons.404.pageTitle': '404',

    //* HEADER
    'commons.header.menu.logout': 'Se déconnecter',
    'commons.header.dialog.title': 'Déconnexion',
    'commons.header.dialog.content': 'Êtes-vous sur de vouloir vous déconnecter ?',
    'commons.header.dialog.cancel': 'Annuler',
    'commons.header.dialog.logout': 'Se déconnecter',

    'commons.shops.none': "Vous n'avez pas de centres",
    'commons.shops.noSelection': 'Choisissez un centre',

    'commons.filters.title': 'Filtrer :',
    'commons.category.label': 'Catégorie',
    'commons.category.value': 'Catégorie : {value}',

    'commons.document.joinedLabel': 'PDF joint',
    'commons.document.noDocument': 'Pas de PDF',

    'commons.validationDialog.myShops': 'Mes centres',
    'commons.validationDialog.switch.successful': 'Vous êtes maintenant sur le centre {name}',

    'commons.publishedBy': 'Publié par {name}',
    'commons.upTo': "Jusqu'au {date}",
    'commons.replyDate': 'Date de réponse :',
    'commons.fromTo': 'Du {from} au {to}',
    'commons.the': 'Le {date}',

    'commons.available': 'En cours',
    'commons.notAvailable': 'Terminé',

    'commons.questions.answerNeeded': '{value} réponses attendues',

    //TODO : revoir en commons
    'commons.noReply': 'Non répondu',
    'commons.positiveReply': "J'ai répondu",
    'commons.negativeReply': 'Je ne participe pas',

    // 'commons.participate.yes': 'Je participe',
    // 'commons.participate.no': 'Je ne participe pas',

    'events.feed.positiveReply': 'Je participe',
    'events.feed.negativeReply': 'Je ne participe pas',

    'communication.participate.label': 'Je participe',
    'communication.unsubmission.label': 'Je ne participe pas',

    'events.participation.isParticipating': 'Je participe',
    'events.participation.notParticipating': 'Je ne participe pas',

    //TODO : revoir en commons : QUESTIONS
    'surveys.questions.itemTitle': 'Question',
    'surveys.questions.next': 'Suivant',
    'surveys.questions.text.placeholder': 'Saisissez votre réponse',

    'gondoles.questions.itemTitle': 'Question',
    'gondoles.questions.next': 'Suivant',
    'gondoles.questions.text.placeholder': 'Saisissez votre réponse',

    //TODO : revoir en commons : VALIDATION
    'events.submission.validateSuccess': 'Réponse envoyée',
    'events.submission.saveError': 'Sauvegarde échouée',
    'events.submission.invalidateError': 'Invalidation échouée',
    'events.submission.repliedDate': 'Réponse le',
    'events.submission.response': 'Réponse',
    'events.validationDialog.title': 'Réponse envoyée !',
    'events.validationDialog.subtitle': 'Votre réponse a bien été prise en compte.',

    'surveys.submission.validateSuccess': 'Réponse envoyée',
    'surveys.submission.saveError': 'Sauvegarde échouée',
    'surveys.submission.invalidateError': 'Invalidation échouée',
    'surveys.submission.repliedDate': 'Réponse le {date}',
    'surveys.validationDialog.title': 'Réponse envoyée !',
    'surveys.validationDialog.subtitle': 'Votre réponse a bien été prise en compte.',

    'gondoles.submission.validateSuccess': 'Réponse envoyée',
    'gondoles.submission.saveError': 'Sauvegarde échouée',
    'gondoles.submission.invalidateError': 'Invalidation échouée',
    'gondoles.submission.repliedDate': 'Réponse le',
    'gondoles.validationDialog.title': 'Réponse envoyée !',
    'gondoles.validationDialog.subtitle': 'Votre réponse a bien été prise en compte.',

    'surveys.validationDialog.duplicateText':
        'Vous avez la possibilité de dupliquer cette réponse pour un autre centre.',
    'surveys.validationDialog.noDuplicate':
        "Vous n'avez pas d'autres centres pour lesqulles vous pouvez dupliquer cette réponse.",
    'surveys.missingQuestions.title': 'Réponses manquantes',
    'surveys.missingQuestions.description':
        'Il semblerait que vous n’ayez pas répondu à toutes les questions, merci de vérifier si vous avez complété toutes les question.',

    'gondoles.validationDialog.duplicateText':
        'Vous avez la possibilité de dupliquer cette réponse pour un autre centre.',
    'gondoles.validationDialog.noDuplicate':
        "Vous n'avez pas d'autres centres pour lesqulles vous pouvez dupliquer cette réponse.",
    'gondoles.missingQuestions.title': 'Réponses manquantes',
    'gondoles.missingQuestions.description':
        'Il semblerait que vous n’ayez pas répondu à toutes les questions, merci de vérifier si vous avez complété toutes les question.',

    'events.validationDialog.duplicateText':
        'Vous avez la possibilité de dupliquer cette réponse pour un autre centre.',
    'events.validationDialog.noDuplicate':
        "Vous n'avez pas d'autres centres pour lesqulles vous pouvez dupliquer cette réponse.",

    //* OTHERS
    'commons.module.videos.content.unavailable':
        "La visualisation des vidéos n'est pas disponible sur l'application Fluizy web. Pour y accéder, vous pouvez utiliser l'application mobile Fluizy disponible pour iPhone et Android.",
    'commons.module.news.content.unavailable':
        "La visualisation des actualités n'est pas disponible sur l'application Fluizy web. Pour y accéder, vous pouvez utiliser l'application mobile Fluizy disponible pour iPhone et Android.",
    'commons.module.audits.content.unavailable':
        "La visualisation des check-lists n'est pas disponible sur l'application Fluizy web. Pour y accéder, vous pouvez utiliser l'application mobile Fluizy disponible pour iPhone et Android.",
    'commons.module.actionPlans.content.unavailable':
        "La visualisation des plans d'action n'est pas disponible sur l'application Fluizy web. Pour y accéder, vous pouvez utiliser l'application mobile Fluizy disponible pour iPhone et Android.",
};

//#region //TODO TOAST MESSAGES

export const frToastMessages = {
    //* GREEN MESSAGE
    'toast.success.update': 'Données mises à jour',
    'toast.success.create': 'Création avec succès',
    'toast.success.manualNotif': 'Relance effectuée.',
    'toast.success.publish': "L'élément a été publié",
    'toast.success.unpublish': "L'élément a été dépublié",
    'toast.success.audienceFromQuestion': "L'audience a été mise à jour dans l'arbre (n'oubliez pas de sauvegarder)",
    'toast.success.password.forgot': 'Demande de réinitialisation envoyée',

    //! ERRORS
    'toast.error.video': "Impossible d'envoyer votre vidéo",
    'toast.apiErrors.403': "Le compte n'est pas autorisé à accéder à l'application.",
    'toast.apiErrors.ALL_QUANTITY_MUST_BE_FILLED': 'Tous les champs doivent être remplis',
    'toast.apiErrors.noShops': 'vous ne disposer de droit sur aucun magasin.',
    'toast.alert.FrontRoleCode.GroupLeader': 'Vous êtes en lecture seule pour ce centre',

    'toast.apiErrors.defaultMessage': "Quelque chose ne s'est pas passé comme prévu, merci de réessayer",

    'toast.apiErrors.EMAIL_NOT_FOUND': "L'addresse email n'a pas été trouvée",
    'toast.apiErrors.GROUP_HAVE_CHILDREN': "Ce groupe n'est pas vide, il ne peut pas être supprimé",
    'toast.apiErrors.FRONT_USER_HAVE_SHOPS': 'Cet utilisateur est associé à des centres, il ne peut pas être supprimé',
    'toast.apiErrors.FRONT_USER_EMAIL_ALREADY_EXISTS': 'Un utilisateur avec le même email existe déjà',
    'toast.apiErrors.BACKOFFICE_USER_EMAIL_ALREADY_EXISTS': 'Un utilisateur avec le même email existe déjà',
    'toast.apiErrors.UNABLE_TO_UPLOAD_DOCUMENT':
        "Nous n'avons pas réussi à envoyer le document, merci de réessayer dans quelques instants",
    'toast.apiErrors.INVALID_DOCUMENT_FORMAT':
        "Le format de ce document n'est supporté, seuls les documents PDF sont autorisés",
    'toast.apiErrors.DOCUMENT_URL_ERROR': "Impossible de récupérer le document à partir de l'url indiquée",
    'toast.apiErrors.UNABLE_TO_UPLOAD_IMAGE':
        "Nous n'avons pas réussi à envoyer l'image, merci de réessayer dans quelques instants",
    'toast.apiErrors.INVALID_IMAGE_FORMAT': "Nous n'avons pas pu traiter cette image, son format ne semble pas valide",
    'toast.apiErrors.IMAGE_URL_ERROR': "Impossible de récupérer l'image à partir de l'url indiquée",
    'toast.apiErrors.EXTERNAL_REFERENCE_INVALID_FORMAT':
        "Le format de la référence externe n'est pas valide. La référence externe ne peut contenir que des lettres, des chiffres et les caractères '_', '-' et '.'",
    'toast.apiErrors.GEOCODING_CANT_CONTACT_API': 'Une erreur est survenue lors de la récupération de la position GPS',
    'toast.apiErrors.GEOCODING_INVALID_RESULT_API':
        'Une erreur est survenue lors de la récupération de la position GPS',
    'toast.apiErrors.GEOCODING_NO_RESULT_FOUND': "Aucune position n'a été trouvée pour cette adresse",
    'toast.apiErrors.EVENT_CANT_DELETE_PUBLISHED': 'Impossible de supprimer un événement qui a été publié',
    'toast.apiErrors.EVENT_ALREADY_PUBLISHED': 'Cet événement est déjà publié',
    'toast.apiErrors.EVENT_MISSING_AUDIENCE':
        "Aucune audience n'est définie pour cet événement, il ne peut pas être publié",
    'toast.apiErrors.EVENT_MISSING_END_DATE':
        "La date d'échéance n'est pas définie pour cet événement, il ne peut pas être publié",
    'toast.apiErrors.EVENT_ALREADY_UNPUBLISHED': 'Cet événement est déjà au statut publication annulée',
    'toast.apiErrors.EVENT_EDIT_DATES_PUBLISHED': 'Cet événement est publié, les dates ne sont plus modifiables',
    'toast.apiErrors.EVENT_NO_DATE': "Aucune date n'est définie pour cet événement, il ne peut pas être publié",
    'toast.apiErrors.EVENT_MISSING_CHOICE_DATE': "La date de début ou la date de fin n'est pas valide",
    'toast.apiErrors.EVENT_INVALID_CHOICE_DATE': 'La date de fin doit être supérieure à la date de début',
    'toast.apiErrors.EVENT_INVALID_END_DATE': "La date d'échéance doit être avant l'événement",
    'toast.apiErrors.REMINDER_CANT_DELETE_PUBLISHED': 'Impossible de supprimer un rappel qui a été publié',
    'toast.apiErrors.REMINDER_ALREADY_PUBLISHED': 'Ce rappel est déjà publié',
    'toast.apiErrors.REMINDER_MISSING_AUDIENCE':
        "Aucune audience n'est définie pour ce rappel, il ne peut pas être publié",
    'toast.apiErrors.REMINDER_ALREADY_UNPUBLISHED': 'Ce rappel est déjà au statut publication annulée',
    'toast.apiErrors.REMINDER_EDIT_DATES_PUBLISHED': 'Ce rappel est publié, les dates ne sont plus modifiables',
    'toast.apiErrors.REMINDER_EDIT_ADDRESS_PUBLISHED': "Ce rappel est publié, l'adresse n'est plus modifiable",
    'toast.apiErrors.REMINDER_MUST_HAVE_DATES': 'La date de début et la date de fin doivent être définies',
    'toast.apiErrors.PACKAGED_ORDER_MISSING_NOTIFICATION_CONFIGURATION':
        "Veuillez configurer l'envoi des notification avant d'effectuer cette opération.",
    'toast.apiErrors.PACKAGED_ORDER_NOT_PUBLISHED': "Cette opération ne peut s'effectuer que sur une offre publiée",
    'toast.apiErrors.PACKAGED_ORDER_CANT_DELETE_PUBLISHED': 'Impossible de supprimer un bon plan qui a été publié',
    'toast.apiErrors.PACKAGED_ORDER_ALREADY_PUBLISHED': 'Ce bon plan est déjà publié',
    'toast.apiErrors.PACKAGED_ORDER_MISSING_END_DATE':
        "La date d'échéance n'est pas définie pour ce bon plan, il ne peut pas être publié",
    'toast.apiErrors.PACKAGED_ORDER_MISSING_UNIT_PRICE':
        "Le prix unitaire n'est pas défini pour ce bon plan, il ne peut pas être publié",
    'toast.apiErrors.PACKAGED_ORDER_MISSING_PRODUCT': 'Le bon plan doit contenir au moins un produit',
    'toast.apiErrors.PACKAGED_ORDER_PRODUCT_MISSING_PURCHASE_PRICE':
        "Le prix d'achat doit être défini pour chaque produit",
    'toast.apiErrors.PACKAGED_ORDER_PRODUCT_MISSING_PURCHASE_PRICE_PROMO':
        "Le prix d'achat promo doit être défini pour chaque produit",
    'toast.apiErrors.PACKAGED_ORDER_PRODUCT_MISSING_MARGIN_RATE':
        'Le taux de marge doit être défini pour chaque produit',
    'toast.apiErrors.PACKAGED_ORDER_PRODUCT_MISSING_MARGIN_RATE_PROMO':
        'Le taux de marge promo doit être défini pour chaque produit',
    'toast.apiErrors.PACKAGED_ORDER_PRODUCT_MISSING_QUANTITY': 'La quantité doit être définie pour chaque produit',
    'toast.apiErrors.PACKAGED_ORDER_PUBLISHED_CANT_ADD_PRODUCT':
        "Impossible d'ajouter un nouveau produit sur un bon plan qui a été publié",
    'toast.apiErrors.PACKAGED_ORDER_PUBLISHED_CANT_EDIT_PRODUCT':
        "Impossible de modifier la référence, le prix d'achat promo ou la quantité d'un produit sur un bon plan qui a déjà été publié",
    'toast.apiErrors.PACKAGED_ORDER_PUBLISHED_CANT_DELETE_PRODUCT':
        'Impossible de supprimer un produit existant sur un bon plan qui a été publié',
    'toast.apiErrors.PACKAGED_ORDER_ALREADY_UNPUBLISHED': 'Ce bon plan est déjà au statut publication annulée',
    'toast.apiErrors.PACKAGED_ORDER_MISSING_AUDIENCE':
        "Aucune audience n'est définie pour ce bon plan, il ne peut pas être publié",
    'toast.apiErrors.PACKAGED_ORDER_PUBLISHED_CANT_EDIT':
        'Ce bon plan est publié, le prix unitaire ne sont plus modifiables',
    'toast.apiErrors.TUNABLE_OFFER_MISSING_NOTIFICATION_CONFIGURATION':
        "Veuillez configurer l'envoi des notification avant d'effectuer cette opération.",
    'toast.apiErrors.TUNABLE_OFFER_NOT_PUBLISHED': "Cette opération ne peut s'effectuer que sur une offre publiée",
    'toast.apiErrors.TUNABLE_OFFER_CANT_DELETE_PUBLISHED': 'Impossible de supprimer un bon plan qui a été publié',
    'toast.apiErrors.TUNABLE_OFFER_ALREADY_PUBLISHED': 'Ce bon plan est déjà publié',
    'toast.apiErrors.TUNABLE_OFFER_MISSING_END_DATE':
        "La date d'échéance n'est pas définie pour ce bon plan, il ne peut pas être publié",
    'toast.apiErrors.TUNABLE_OFFER_MISSING_PRODUCT': 'Le bon plan doit contenir au moins un produit',
    'toast.apiErrors.TUNABLE_OFFER_PRODUCT_MISSING_PURCHASE_PRICE':
        "Le prix d'achat doit être défini pour chaque produit",
    'toast.apiErrors.TUNABLE_OFFER_PRODUCT_MISSING_PURCHASE_PRICE_PROMO':
        "Le prix d'achat promo doit être défini pour chaque produit",
    'toast.apiErrors.TUNABLE_OFFER_PRODUCT_MISSING_MARGIN_RATE':
        'Le taux de marge doit être défini pour chaque produit',
    'toast.apiErrors.TUNABLE_OFFER_PRODUCT_MISSING_MARGIN_RATE_PROMO':
        'Le taux de marge promo doit être défini pour chaque produit',
    'toast.apiErrors.TUNABLE_OFFER_PUBLISHED_CANT_ADD_PRODUCT':
        "Impossible d'ajouter un nouveau produit sur un bon plan qui a été publié",
    'toast.apiErrors.TUNABLE_OFFER_PUBLISHED_CANT_EDIT_PRODUCT':
        "Impossible de modifier la référence, le prix d'achat promo ou la quantité d'un produit sur un bon plan qui a déjà été publié",
    'toast.apiErrors.TUNABLE_OFFER_PUBLISHED_CANT_DELETE_PRODUCT':
        'Impossible de supprimer un produit existant sur un bon plan qui a été publié',
    'toast.apiErrors.TUNABLE_OFFER_ALREADY_UNPUBLISHED': 'Ce bon plan est déjà au statut publication annulée',
    'toast.apiErrors.TUNABLE_OFFER_MISSING_AUDIENCE':
        "Aucune audience n'est définie pour ce bon plan, il ne peut pas être publié",
    'toast.apiErrors.TUNABLE_OFFER_PUBLISHED_CANT_EDIT':
        'Ce bon plan est publié, le prix unitaire ne sont plus modifiables',
    'toast.apiErrors.TUNABLE_OFFER_MISSING_TARGET_QUANTITY': 'Veuillez renseigner la quantitée cible totale',
    'toast.apiErrors.QUESTIONNAIRE_PUBLISHED_CANT_EDIT':
        "Impossible de modifier la structure d'un questionnaire après publication",
    'toast.apiErrors.QUESTIONNAIRE_MISSING_QUESTIONS': 'Le questionnaire doit contenir au moins une question',
    'toast.apiErrors.QUESTIONNAIRE_QUESTION_CHOICE_LABEL_MISSING': 'Chaque réponse possible doit avoir un libellé',
    'toast.apiErrors.QUESTIONNAIRE_QUESTION_MISSING_QUESTION':
        'Un libellé de question doit être défini pour chaque question',
    'toast.apiErrors.QUESTIONNAIRE_QUESTION_MISSING_TITLE': 'Un titre doit être défini pour chaque question',
    'toast.apiErrors.QUESTIONNAIRE_QUESTION_NOT_ENOUGH_CHOICES':
        'Chaque question choix doit proposer au moins deux réponses possibles',
    'toast.apiErrors.QUESTIONNAIRE_QUESTION_SINGLE_CHOICE_ACTION_WHEN_SELECTED_UNDEFINED':
        "Le comportement de question suivante n'est pas bien défini pour une des question à choix unique",
    'toast.apiErrors.QUESTIONNAIRE_QUESTION_SINGLE_CHOICE_GO_TO_QUESTION_ID_BAD_REFERENCE':
        "Le comportement de question suivante n'est pas bien défini pour une des question à choix unique",
    'toast.apiErrors.QUESTIONNAIRE_QUESTION_SINGLE_CHOICE_GO_TO_QUESTION_ID_UNDEFINED':
        "Le comportement de question suivante n'est pas bien défini pour une des question à choix unique",
    'toast.apiErrors.QUESTIONNAIRE_QUESTION_SINGLE_CHOICE_LABEL_NOT_DEFINED':
        'Chaque réponse possible doit avoir un libellé',
    'toast.apiErrors.QUESTIONNAIRE_QUESTION_CHOICE_NOT_FOUND': 'Aucune réponse ne correspond à cette référence',
    'toast.apiErrors.QUESTIONNAIRE_QUESTION_CHOICE_AMBIGUOUS_EXTERNAL_REFERENCE':
        'Plusieurs réponses correspondent à cette référence',
    'toast.apiErrors.SURVEY_ALREADY_PUBLISHED': 'Ce sondage est déjà publié',
    'toast.apiErrors.SURVEY_MISSING_AUDIENCE':
        "Aucune audience n'est définie pour ce sondage, il ne peut pas être publié",
    'toast.apiErrors.SURVEY_MISSING_END_DATE':
        "La date d'échéance n'est pas définie pour ce sondage, il ne peut pas être publié",
    'toast.apiErrors.SURVEY_MISSING_TITLE': "Le titre n'est pas défini pour ce sondage, il ne peut pas être publié",
    'toast.apiErrors.SURVEY_CANT_DELETE_PUBLISHED': 'Impossible de supprimer un sondage qui a été publié',
    'toast.apiErrors.VIDEO_CAN_NOT_BE_DELETED': 'Impossible de supprimer une vidéo qui a été publiée',
    'toast.apiErrors.VIDEO_ALREADY_PUBLISHED': 'Cette vidéo est déjà publiée',
    'toast.apiErrors.VIDEO_ALREADY_UNPUBLISHED': "Cette vidéo est déjà à l'état publication annulée",
    'toast.apiErrors.VIDEO_MISSING_AUDIENCE':
        "Aucune audience n'est définie pour cette vidéo, il ne peut pas être publié",
    'toast.apiErrors.VIDEO_MISSING_FILE':
        'Un fichier vidéo doit être envoyé pour permettre de publié cet instant digital',
    'toast.apiErrors.VIDEO_FILE_NOT_READY':
        "Le traitement de la vidéo n'est pas encore terminé, merci de réessayer dans quelques instants",
    'toast.apiErrors.VIDEO_FILE_NOT_SUPPORTED':
        "Nous n'avons pas pu traiter cette vidéo, son format ne semble pas valide",
    'toast.apiErrors.SURVEY_REPLY_VALIDATED_CANNOT_BE_UPDATED':
        "Vous ne pouvez pas changer une réponse d'un sondage déja validé",
    'toast.apiErrors.QUESTIONNAIRE_ANSWER_CHOICE_NOT_AVAILABLE':
        "Vous navez pas choisi une réponse correcte à l'une des questions",
    'toast.apiErrors.EVENT_EXPIRED': "Vous ne pouvez plus répondre à cet évènement, la date d'échéance est pasée",
    'toast.apiErrors.GROUP_LEADER_READONLY': "Vous n'avez pas les droits pour faire cette action",
};

//#endregion
