import { withFuncFactory } from '../lib/IdeLink.Web.Common/src/Factory';
import { CommunicationsModule } from '../modules/Communications/CommunicationsModule';
import { ACTION_PLANS_MODULE_CODE, AUDITS_MODULE_CODE } from '../modules/Dashboard/Helpers/DashboardHelper';
import { EventsModule } from '../modules/Events/EventsModule';
import { GondolesModule } from '../modules/Gondoles/GondolesModule';
import { OffersModule } from '../modules/Offers/OffersModule';
import { OperationsModule } from '../modules/Operations/OperationsModule';
import { SurveysModule } from '../modules/Surveys/SurveysModule';

export const getDefaultModuleNamesMap = () => {
    return {
        Core: 'Core',
        [OffersModule.OFFERS_MODULE_CODE]: OffersModule.OFFERS_MODULE_CODE,
        [OperationsModule.OPERATIONS_MODULE_CODE]: OperationsModule.OPERATIONS_MODULE_CODE,

        [CommunicationsModule.COMMUNICATION_GROUP_CODE]: CommunicationsModule.COMMUNICATION_GROUP_CODE,
        //* GROUPS
        //! todo @mmc, voire pourquoi on a un list comme valeur
        [CommunicationsModule.COMMUNICATION_GROUP_ITEMS_CODE]: [
            CommunicationsModule.DIGITAL_MARKETINGS_MODULE_CODE,
            CommunicationsModule.PARTNERSHIPS_MODULE_CODE,
            CommunicationsModule.LEAFLETS_MODULE_CODE,
        ],
        [CommunicationsModule.DIGITAL_MARKETINGS_MODULE_CODE]: CommunicationsModule.DIGITAL_MARKETINGS_MODULE_CODE,
        [CommunicationsModule.PARTNERSHIPS_MODULE_CODE]: CommunicationsModule.PARTNERSHIPS_MODULE_CODE,
        [CommunicationsModule.LEAFLETS_MODULE_CODE]: CommunicationsModule.LEAFLETS_MODULE_CODE,

        [EventsModule.EVENTS_MODULE_CODE]: EventsModule.EVENTS_MODULE_CODE,
        [SurveysModule.SURVEYS_MODULE_CODE]: SurveysModule.SURVEYS_MODULE_CODE,
        [GondolesModule.GONDOLES_MODULE_CODE]: GondolesModule.GONDOLES_MODULE_CODE,

        [AUDITS_MODULE_CODE]: AUDITS_MODULE_CODE,
        [ACTION_PLANS_MODULE_CODE]: ACTION_PLANS_MODULE_CODE,
        //TODO [VideosModule.VIDEOS_MODULE_CODE]: VideosModule.VIDEOS_MODULE_CODE,
        //TODO [NewsModule.NEWS_MODULE_CODE]: NewsModule.NEWS_MODULE_CODE,
    };
};

export const getModuleNamesMapFactory = withFuncFactory(getDefaultModuleNamesMap);
export const getModuleName = (key: string): string => getModuleNamesMapFactory()[key];

//todo : rename to getModuleNameKey : used by analytics only
export const getModuleOverrideFor = (moduleName: string) => {
    const moduleNamesMap = getModuleNamesMapFactory();
    const entry = Object.entries(moduleNamesMap)
        .reverse()
        .find(([_, value]) => value === moduleName);
    return !entry ? '' : entry[0];
};
