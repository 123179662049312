import './ActionPlansTheme.scss';

import { IdelinkModuleTheme } from '../../../xlink/configs/IdeLinkTheme';
import feedImage from '../assets/default-img-actionPlans-square.svg';
import iconWhite from '../assets/ic-actionPlans-white.svg';
import iconColor from '../assets/ic-actionPlans.svg';

export const ActionPlansTheme: IdelinkModuleTheme = {
    className: 'idelink-theme il-theme-actionPlans',
    backgroundColor: '#FE9901',
    feedImage,
    iconColor,
    iconWhite,
};
