import { formatDateCustom, formatDateShort } from '../../lib/IdeLink.Web.Common/src/utils/dateUtils';

const toTotalPrice = (rawTotalPrice: string): string => {
    let totalPrice: number;
    try {
        totalPrice = Number.parseFloat(rawTotalPrice);
    } catch (e) {
        totalPrice = 0;
    }
    return totalPrice.toFixed(2);
};

export const esFuncs = {
    Feed_Core_Welcome: ({ firstName }: any) =>
        `Bienvenidos ${firstName ||
            ''} !!! Encantados de darte la bienvenida a Fluizy. Estamos aquí para acompañarte a diario.`,
    Feed_Audits_FEED_AUDIT_AUTO_AUDIT_CREATED: () => 'La evaluación se ha creado con éxito, no olvides finalizarla.',
    Feed_Audits_FEED_AUDIT_DONE: () => 'La evaluación está terminada, bien!',
    Feed_Audits_FEED_AUDIT_SHOP_APPROVAL_PENDING: () => 'Tu animador espera la validación...',
    Feed_Audits_FEED_AUTO_CHECK_CREATED: () => 'Tienes una nueva tarea pendiente a revisar',
    Feed_Audits_FEED_REMINDER_EVENT: ({ RemainingDays }: any) =>
        `Sólo quedan ${RemainingDays} días para responder a la evaluación, después será demasiado tarde.`,
    Feed_DigitalMarketings_FEED_INVALIDATE_EVENT: ({ RemainingDays = 0 }: any) =>
        `¿Tienes dudas? Has cancelado tu respuesta, tiene ${RemainingDays} días para responder.`,
    Feed_DigitalMarketings_FEED_INVALIDATE_LAST_DAY_EVENT: () =>
        '¿Tienes dudas? Has cancelado tu respuesta, solo tiene unas pocas horas para responder.',
    Feed_DigitalMarketings_FEED_PUBLISH_EVENT: ({ EndDate }: any) =>
        `Una nueva operación de comunicación digital está disponible. Tiene hasta ${formatDateShort(
            EndDate
        )} para responder.`,
    Feed_DigitalMarketings_FEED_REMINDER_EVENT: ({ RemainingDays = 0 }: any) =>
        `Tienes ${RemainingDays} para responder a esta operación de comunicación digital.`,
    Feed_DigitalMarketings_FEED_REPLIED_NEGATIVE_EVENT: () =>
        'No está interesado en esta operación de comunicación digital, su respuesta se ha tenido en cuenta.',
    Feed_DigitalMarketings_FEED_REPLIED_POSITIVE_EVENT: ({ TotalPrice }: any) =>
        `Ha aceptado la participación en esta operación de comunicación digital por una cantidad de ${toTotalPrice(
            TotalPrice
        )} €.`,
    Feed_Events_FEED_POSTPONE_EVENT: ({ RemainingDays = 0 }: any) =>
        `La fecha de vencimiento ha cambiado, solo te quedan ${RemainingDays} días para responder.`,
    Feed_Events_FEED_POSTPONE_LAST_DAY_EVENT: () =>
        'Atención, la fecha de vencimiento ha cambiado, solo te quedan unas horas para responder.',
    Feed_Events_FEED_PREPONE_EVENT: ({ RemainingDays = 0 }: any) =>
        `La fecha de vencimiento ha cambiado, solo te quedan ${RemainingDays} días para responder.`,
    Feed_Events_FEED_PREPONE_LAST_DAY_EVENT: () =>
        'Atención, la fecha de vencimiento ha cambiado, solo te quedan unas horas para responder.',
    Feed_Events_FEED_PUBLISH_EVENT: ({ RemainingDays = 0 }: any) =>
        `Mira tu calendario, has recibido una nueva invitación. Tiene ${RemainingDays} días para confirmar su participación.`,
    Feed_Events_FEED_PUBLISH_LAST_DAY_EVENT: () =>
        'MIra tu calendario, has recibido una nueva invitación. Tiene algunas horas para confirmar su participación.',
    Feed_Events_FEED_REMINDER_LAST_DAY_SENT_EVENT: () =>
        'Solo unas pocas horas para confirmar su participación. Después, será demasiado tarde.',
    Feed_Events_FEED_REMINDER_SENT_EVENT: ({ RemainingDays = 0 }: any) =>
        `Sólo quedan ${RemainingDays} días para confirmar tu participación. Después de que sea demasiado tarde.`,
    Feed_Events_FEED_REPLY_INVALIDATED_EVENT: ({ RemainingDays = 0 }: any) =>
        `¿Has cambiado de opinión? Ha cancelado tu respuesta, tiene ${RemainingDays} días para enviarla nuevamente.`,
    Feed_Events_FEED_REPLY_INVALIDATED_LAST_DAY_EVENT: () =>
        '¿Has cambiado de opinión? Has cancelado tu respuesta, solo tiene unas pocas horas para enviarla nuevamente.',
    Feed_Events_FEED_REPLY_SAVED_EVENT: () =>
        'Hemos guardado tu respuesta. Ya falta poco, estamos esperando que lo completes.',
    Feed_Events_FEED_REPLY_VALIDATED_EVENT: () =>
        'Has confirmado tu participación. Estaremos encantados de tenerte con nosotros.',
    Feed_Events_FEED_START_TODAY_EVENT: ({ Title }: any) => `Ya estamos aquí. Hoy comienza el evento ${Title} `,
    Feed_Events_FEED_START_TOMORROW_EVENT: ({ EventStartDate, Address }: any) =>
        `¡Mañana es el gran día! Este evento tendrá lugar en ${formatDateCustom(
            EventStartDate,
            'HH: MM',
            '-'
        )} a ${Address}.`,
    Feed_Events_FEED_START_TOMORROW_NO_LOCATION_EVENT: ({ EventStartDate }: any) =>
        `¡Mañana es el gran día! Este evento tendrá lugar en ${formatDateCustom(EventStartDate, 'HH: MM', '-')}.`,
    Feed_Events_FEED_TOO_LATE_EVENT: () => 'Ups… tu respuesta no ha llegado a tiempo. Contamos contigo a la próxima.',
    Feed_Gondolas_FEED_POSTPONE_EVENT: ({ RemainingDays }: any) =>
        `La fecha de vencimiento ha cambiado, solo te quedan ${RemainingDays} días para responder.`,
    Feed_Gondolas_FEED_POSTPONE_LAST_DAY_EVENT: () =>
        'Atención, la fecha de vencimiento ha cambiado, solo te quedan unas horas para responder.',
    Feed_Gondolas_FEED_PPREPONE_EVENT: ({ RemainingDays }: any) =>
        `La fecha de vencimiento ha cambiado, solo fáltan ${RemainingDays} días para responder.`,
    Feed_Gondolas_FEED_PREPONE_LAST_DAY_EVENT: () =>
        'La fecha de vencimiento ha cambiado, solo te quedan unas pocas horas para responder.',
    Feed_Gondolas_FEED_PUBLISH_EVENT: ({ EndDate }: any) =>
        `¡Tu opinión cuenta! Se ha publicado un nuevo cuestionario de posicionamiento de Góndola. Cierre de respuestas ${formatDateCustom(
            EndDate,
            'DD/MM/YYYY',
            '-'
        )}. `,
    Feed_Gondolas_FEED_PUBLISH_LAST_DAY_EVENT: () =>
        '¡Tu opinión cuenta!  Se ha publicado un nuevo cuestionario de posicionamiento de Góndola. Cierre de las respuestas en unas pocas horas.',
    Feed_Gondolas_FEED_REMINDER_EVENT: () => `No tienes mucho tiempo para responder, luego será demasiado tarde.`,
    Feed_Gondolas_FEED_REMINDER_LAST_DAY_EVENT: () =>
        'Solo tienes unas pocas horas para validar tu respuesta, luego será demasiado tarde.',
    Feed_Gondolas_FEED_REMINDER_LAST_DAY_SENT_EVENT: () =>
        `Solo quedan unas pocas horas para dar su opinión, luego será demasiado tarde.`,
    Feed_Gondolas_FEED_REMINDER_SENT_EVENT: ({ RemainingDays }: any) =>
        `Solo quedan ${RemainingDays} días para dar su opinión, luego será demasiado tarde.`,
    Feed_Gondolas_FEED_REPLY_INVALIDATED_EVENT: ({ RemainingDays }: any) =>
        `¿Tienes dudas? Has anulado tu respuesta. ¡Quedan ${RemainingDays} días para responder!`,
    Feed_Gondolas_FEED_REPLY_INVALIDATED_LAST_DAY_EVENT: () =>
        '¿Tienes dudas? Has anulado tu respuesta, solo tiene unas pocas horas para validar su respuesta.',
    Feed_Gondolas_FEED_REPLY_SAVED_EVENT: () =>
        'Hemos guardado tus respuestas. No olvide finalizar y validar el envío.',
    Feed_Gondolas_FEED_REPLY_VALIDATED_EVENT: () => `¡Buen trabajo! Hemos recibido su respuesta.`,
    Feed_Gondolas_FEED_TOO_LATE_EVENT: () =>
        'Que pena, el plazo de respuesta ha terminado… Contamos con tu opinión la próxima vez.',
    Feed_GoodDeals_FEED_INCREMENTAL_SAVE_EVENT: () => 'Hemos guardado tu cesta.',
    Feed_GoodDeals_FEED_INVALIDATE_ORDER_EVENT: ({ RemainingDays }: any) =>
        `¿Alguna duda? Has cancelado tu pedido, tienes ${RemainingDays} días para resolverlo`,
    Feed_GoodDeals_FEED_INVALIDATE_ORDER_LAST_DAY_EVENT: ({ RemainingHours }: any) =>
        `¿Alguna duda? Has cancelado tu pedido, tienes ${RemainingHours} horas para resolverlo`,
    Feed_GoodDeals_FEED_INVALIDATE_ORDER_LAST_HOUR_EVENT: () =>
        '¿Alguna duda? Has cancelado tu pedido, solo quedan unos minutos para volver a presentarlo.',
    Feed_GoodDeals_FEED_ORDER_EVENT: ({ TotalPrice }: any) =>
        `¡Ojo! Tu pedido de ${toTotalPrice(TotalPrice)} € está en las tuberías.`,
    Feed_GoodDeals_FEED_PUBLISH_EVENT: () => '¡Una ganga, acaba de llegar una nueva oferta!',
    Feed_GoodDeals_FEED_REMINDER_EVENT: () =>
        'Todavía no has consultado esta offerta, ¡merece la pena echarle un vistazo!',
    Feed_GoodDeals_FEED_SOLD_OUT_ORDERED_EVENT: () =>
        'Fin, ¡el stock está agotado! ¡Deberías haber aprovechado el momento!',
    Feed_Leaflets_FEED_INVALIDATE_EVENT: ({ RemainingDays = 0 }: any) =>
        `¿Tienes dudas? Has cancelado tu respuesta, tienes ${RemainingDays} días para responder.`,
    Feed_Leaflets_FEED_INVALIDATE_LAST_DAY_EVENT: () =>
        '¿Tienes dudas? Has cancelado tu respuesta, solo tienes unas pocas horas para responder.',
    Feed_Leaflets_FEED_PUBLISH_EVENT: ({ EndDate }: any) =>
        `Una nueva pedido de folleto está disponible. Tiene hasta ${formatDateShort(EndDate, '-')} para responder.`,
    Feed_Leaflets_FEED_REMINDER_EVENT: ({ RemainingDays = 0 }: any) =>
        `Usted tiene ${RemainingDays} para responder a este folleto.`,
    Feed_Leaflets_FEED_REPLIED_NEGATIVE_EVENT: () =>
        'No está interesado en este pedido de folleto, su respuesta se ha tenido en cuenta.',
    Feed_Leaflets_FEED_REPLIED_POSITIVE_EVENT: ({ TotalPrice }: any) =>
        `Usted ha aceptado la participación en esta pedido de folleto por una cantidad de ${toTotalPrice(
            TotalPrice
        )} €.`,
    Feed_News_FEED_LIKED_EVENT: () => 'Esta es una noticia interesante. Le has dado "me gusta".',
    Feed_News_FEED_PUBLISH_EVENT: () => 'Hay una nueva noticia disponible.',
    Feed_News_FEED_REMINDER_EVENT: () => 'Todavía no has consultado esta noticia.',
    Feed_Offers_FEED_INCREMENTAL_SAVE_EVENT: () => 'Hemos guardado tu cesta.',
    Feed_Offers_FEED_INVALIDATE_ORDER_EVENT: ({ RemainingDays }: any) =>
        `¿Tienes dudas? Cancelaste tu pedido, tiene ${RemainingDays} días para volver a enviarlo`,
    Feed_Offers_FEED_INVALIDATE_ORDER_LAST_DAY_EVENT: ({ RemainingHours }: any) =>
        `¿Tienes dudas? Cancelaste tu pedido, tiene ${RemainingHours} horas para volver a enviarlo`,
    Feed_Offers_FEED_INVALIDATE_ORDER_LAST_HOUR_EVENT: () =>
        '¿Tienes dudas? Cancelaste tu pedido, solo le quedan unos minutos para volver a enviarlo',
    Feed_Offers_FEED_ORDER_EVENT: ({ TotalPrice }: any) => `Tu pedido de ${toTotalPrice(TotalPrice)} € está pendiente.`,
    Feed_Offers_FEED_PUBLISH_EVENT: () => '¡Un chollo, acaba de llegar una nueva oferta!',
    Feed_Offers_FEED_REMINDER_EVENT: () => 'Aún no ha consultado esta oferta, ¡merece la pena echarle un vistazo!',
    Feed_Offers_FEED_SOLD_OUT_ORDERED_EVENT: () =>
        'Fin, ¡el stock está agotado! ¡Deberías haber aprovechado el momento!',
    Feed_Operations_FEED_INCREMENTAL_SAVE_EVENT: () => 'Hemos guardado tu cesta.',
    Feed_Operations_FEED_INVALIDATE_ORDER_EVENT: ({ RemainingDays }: any) =>
        `¿Tienes dudas? Has cancelado tu pedido, tiene ${RemainingDays} días para resolverlo`,
    Feed_Operations_FEED_INVALIDATE_ORDER_LAST_DAY_EVENT: ({ RemainingHours }: any) =>
        `¿Tienes dudas? Has cancelado tu pedido, tienes ${RemainingHours} horas para resolverlo`,
    Feed_Operations_FEED_INVALIDATE_ORDER_LAST_HOUR_EVENT: () =>
        '¿Dudas? Has cancelado tu pedido, solo tienes unos minutos para confirmarlo.',
    Feed_Operations_FEED_ORDER_EVENT: ({ TotalPrice }: any) =>
        `Tatán! Su pedido de ${toTotalPrice(TotalPrice)} € está pendiente.`,
    Feed_Operations_FEED_PUBLISH_EVENT: () => 'Una tarea pendiente, ¡acaba de llegar una nueva oferta!',
    Feed_Operations_FEED_REMINDER_EVENT: () => 'Todavía no has consultado este plan de ventas, ¡merece una ojeada!',
    Feed_Operations_FEED_SOLD_OUT_ORDERED_EVENT: () =>
        'Fin, ¡el stock está agotado! ¡Deberías haber aprovechado el momento!',
    Feed_PackagedOrders_FEED_ORDER_EVENT: ({ TotalPrice }: any) =>
        `Tatán! Su pedido de ${toTotalPrice(TotalPrice)} € está pendiente.`,
    Feed_PackagedOrders_FEED_PUBLISH_EVENT: () => '¡Un asunto que hacer, un nueva oferta acaba de aparecer!',
    Feed_PackagedOrders_FEED_REMINDER_EVENT: () => 'Todavía no has consultado esta oferta, ¡merece una ojeada!',
    Feed_PackagedOrders_FEED_SOLD_OUT_EVENT: () =>
        '¡Demasiado tarde, el stock se ha agotado! Tendrás que estar más atent@ la próxima vez.',
    Feed_Partnerships_FEED_INVALIDATE_EVENT: ({ RemainingDays = 0 }: any) =>
        `¿Tienes dudas? Has cancelado tu respuesta, tienes ${RemainingDays} días para responder.`,
    Feed_Partnerships_FEED_INVALIDATE_LAST_DAY_EVENT: () =>
        '¿Tienes dudas? Has cancelado tu respuesta, solo tienes unas pocas horas para responder.',
    Feed_Partnerships_FEED_PUBLISH_EVENT: ({ EndDate }: any) =>
        `Una nueva operación de asociación está disponible. Tiene hasta ${formatDateShort(
            EndDate,
            '-'
        )} para responder.`,
    Feed_Partnerships_FEED_REMINDER_EVENT: ({ RemainingDays = 0 }: any) =>
        `Tienes ${RemainingDays} para responder a esta operación de asociación.`,
    Feed_Partnerships_FEED_REPLIED_NEGATIVE_EVENT: () =>
        'No estás interesado en esta operación de asociación, tu respuesta se ha tenido en cuenta.',
    Feed_Partnerships_FEED_REPLIED_POSITIVE_EVENT: ({ TotalPrice }: any) =>
        `Has aceptado la participación en esta operación de asociación por una cantidad de ${toTotalPrice(
            TotalPrice
        )} €.`,
    Feed_Reminders_FEED_REMINDER_STARTED: () => '¡Ya está aquí! ¡Este evento comienza hoy!',
    Feed_Surveys_FEED_POSTPONE_EVENT: ({ RemainingDays = 0 }: any) =>
        `La fecha de vencimiento ha cambiado, solo te quedan ${RemainingDays} días para responder.`,
    Feed_Surveys_FEED_POSTPONE_LAST_DAY_EVENT: () =>
        'Atención, la fecha de vencimiento ha cambiado, solo te quedan unas horas para responder.',
    Feed_Surveys_FEED_PREPONE_EVENT: ({ RemainingDays = 0 }: any) =>
        `Todo lo acelera, la fecha de vencimiento ha cambiado, todo lo que tiene que hacer es ${RemainingDays} días para responder`,
    Feed_Surveys_FEED_PREPONE_LAST_DAY_EVENT: () =>
        'Atención, la fecha de vencimiento ha cambiado, solo te quedan unas horas para responder.',
    Feed_Surveys_FEED_PUBLISH_EVENT: ({ EndDate }: any) =>
        `Necesitamos tu opinión. Un nuevo cuestionario ha sido publicado. El plazo finaliza el ${formatDateCustom(
            EndDate,
            'dd/MM/yyyy',
            '-'
        )}`,
    Feed_Surveys_FEED_PUBLISH_LAST_DAY_EVENT: () =>
        'Necesitamos tu opinión. Un nuevo cuestionario ha sido publicado. El plazo finaliza en unas horas.',
    Feed_Surveys_FEED_REMINDER_LAST_DAY_SENT_EVENT: () =>
        'Solo quedan unas pocas horas para dar su opinión, después de que será demasiado tarde.',
    Feed_Surveys_FEED_REMINDER_SENT_EVENT: ({ RemainingDays = 0 }: any) =>
        `Sólo quedan ${RemainingDays} días para dar tu opinión, después de que será demasiado tarde.`,
    Feed_Surveys_FEED_REPLY_INVALIDATED_EVENT: ({ RemainingDays = 0 }: any) =>
        `¿Tienes dudas? Has anulado tu respuesta. ¡Quedan ${RemainingDays} días para responder!`,
    Feed_Surveys_FEED_REPLY_INVALIDATED_LAST_DAY_EVENT: () =>
        '¿Tienes dudas? Has anulado tu respuesta. ¡Sólo quedan unas horas para responder!',
    Feed_Surveys_FEED_REPLY_SAVED_EVENT: () => 'Hemos guardado tus respuestas. Sólo queda un poco más, ¡adelante!',
    Feed_Surveys_FEED_REPLY_VALIDATED_EVENT: () => 'Gracias! Hemos recibido tu respuesta correctamente.',
    Feed_Surveys_FEED_TOO_LATE_EVENT: () =>
        'Que pena, el plazo de respuesta ha terminido… Contamos con tu opinión la próxima vez.',
    Feed_Videos_FEED_PUBLISH_EVENT: () => 'Se ha publicado un nuevo video. Los nominados son ...',
    Feed_Videos_FEED_REMINDER_EVENT: () => 'Todavía no has visto este vídeo, se siente abandonado…',
    'Feed.Titles.Core.Welcome': 'Bienvenidos',

    
    //! Action Plans
    Feed_ActionPlans_FEED_ACTION_PLAN_CREATED: () => "Se le ha asignado un nuevo plan de acción.",
    Feed_ActionPlans_FEED_ACTION_PLAN_CREATED_ASSIGNEE: ({AssignedToFirstName, AssignedToLastName}: any) => `Se ha asignado un nuevo plan de acción a ${AssignedToFirstName} ${AssignedToLastName}.`,
    Feed_ActionPlans_FEED_ACTION_PLAN_TRANSFER: () => `Se le ha asignado el plan de acción.`,
    Feed_ActionPlans_FEED_ACTION_PLAN_TRANSFER_ASSIGNEE: ({AssignedToFirstName, AssignedToLastName}: any) => `El plan de acción ha sido asignado a ${AssignedToFirstName} ${AssignedToLastName}.`,
    Feed_ActionPlans_FEED_ACTION_PLAN_CLOSED: () => `Ha completado el plan de acción.`,
    Feed_ActionPlans_FEED_ACTION_PLAN_CLOSED_INITIATOR: ({InitiatorFirstName, InitiatorLastName}: any) => `El plan de acción ha sido completado por ${InitiatorFirstName} ${InitiatorLastName}.`,
    Feed_ActionPlans_FEED_ACTION_PLAN_DROPPED: () => `Has abandonado el plan de acción.`,
    Feed_ActionPlans_FEED_ACTION_PLAN_DROPPED_INITIATOR: ({InitiatorFirstName, InitiatorLastName}: any) => `El plan de acción ha sido abandonado por ${InitiatorFirstName} ${InitiatorLastName}.`,
    Feed_ActionPlans_FEED_ACTION_PLAN_NOTIFY_INITIATOR: ({InitiatorFirstName, InitiatorLastName}: any) => `${InitiatorFirstName} ${InitiatorLastName} le ha enviado un recordatorio sobre este plan de acción.`,
    Feed_ActionPlans_FEED_ACTION_PLAN_NOTIFY_ASSIGNEE_AND_INITIATOR: ({InitiatorFirstName, InitiatorLastName, AssignedToFirstName, AssignedToLastName}: any) => `${InitiatorFirstName} ${InitiatorLastName} ha enviado un recordatorio a ${AssignedToFirstName} ${AssignedToLastName} en relación con este plan de acción.`,
    Feed_ActionPlans_FEED_ACTION_PLAN_NOTIFY_ASSIGNEE: ({AssignedToFirstName, AssignedToLastName}: any) => `Usted ha enviado un recordatorio a ${AssignedToFirstName} ${AssignedToLastName} en relación con este plan de acción.`,
    Feed_ActionPlans_FEED_ACTION_PLAN_OPEN: () => `Has reabierto el plan de acción.`,
    Feed_ActionPlans_FEED_ACTION_PLAN_OPEN_INITIATOR: ({InitiatorFirstName, InitiatorLastName}: any) => `${InitiatorFirstName} ${InitiatorLastName} ha reabierto el plan de acción.`,
};
