import React from 'react';

import { ActionPlansTheme } from '../../modules/ActionPlans/Theme/ActionPlansTheme';
import { AuditsTheme } from '../../modules/Audits/Theme/AuditsTheme';
import { CommunicationsModule } from '../../modules/Communications/CommunicationsModule';
import {
    CommunicationsTheme,
    DigitalmarketingsTheme,
    LeafletsTheme,
    PartnershipsTheme,
} from '../../modules/Communications/Theme/CommunicationsTheme';
import { ACTION_PLANS_MODULE_CODE, AUDITS_MODULE_CODE } from '../../modules/Dashboard/Helpers/DashboardHelper';
import { EventsModule } from '../../modules/Events/EventsModule';
import { EventsTheme } from '../../modules/Events/Theme/EventsTheme';
import { GondolesModule } from '../../modules/Gondoles/GondolesModule';
import { GondolesTheme } from '../../modules/Gondoles/Theme/GondolesTheme';
import { OffersModule } from '../../modules/Offers/OffersModule';
import { OffersTheme } from '../../modules/Offers/Theme/OffersTheme';
import { OperationsModule } from '../../modules/Operations/OperationsModule';
import { EngagmentsTheme, OperationsTheme } from '../../modules/Operations/Theme/OperationsTheme';
import { SurveysModule } from '../../modules/Surveys/SurveysModule';
import { SurveysTheme } from '../../modules/Surveys/Theme/SurveysTheme';

export interface IdelinkModuleTheme {
    readonly className: string;
    readonly backgroundColor: string;
    readonly feedImage?: string;
    readonly emptyIcon?: string;
    readonly iconColor: string;
    readonly iconWhite: string;
    readonly productImage?: string;
}

export interface IdeLinkTheme {
    offers: IdelinkModuleTheme;

    operations: IdelinkModuleTheme;
    engagements: IdelinkModuleTheme;

    communications: IdelinkModuleTheme;
    digitalMarketings: IdelinkModuleTheme;
    partnerships: IdelinkModuleTheme;
    leaflets: IdelinkModuleTheme;

    events: IdelinkModuleTheme;
    surveys: IdelinkModuleTheme;
    gondoles: IdelinkModuleTheme;

    audits: IdelinkModuleTheme;
    actionPlans: IdelinkModuleTheme;
}

export const DefaultIdelinkTheme: IdeLinkTheme = {
    offers: OffersTheme,

    operations: OperationsTheme,
    engagements: EngagmentsTheme,

    communications: CommunicationsTheme,
    digitalMarketings: DigitalmarketingsTheme,
    partnerships: PartnershipsTheme,
    leaflets: LeafletsTheme,

    events: EventsTheme,
    surveys: SurveysTheme,
    gondoles: GondolesTheme,

    audits: AuditsTheme,
    actionPlans: ActionPlansTheme,
};

export const IdelinkThemeContext = React.createContext<IdeLinkTheme>(DefaultIdelinkTheme);
export const useIdelinkTheme = () => React.useContext(IdelinkThemeContext);

export class IdelinkThemeHelper {
    public static getTheme = (moduleName: string, ideLinkTheme: IdeLinkTheme): IdelinkModuleTheme => {
        switch (moduleName) {
            case OperationsModule.OPERATIONS_MODULE_CODE:
                return ideLinkTheme.operations;
            case OffersModule.OFFERS_MODULE_CODE:
                return ideLinkTheme.offers;

            case CommunicationsModule.COMMUNICATION_GROUP_CODE:
            case CommunicationsModule.COMMUNICATION_GROUP_ITEMS_CODE:
                return ideLinkTheme.communications;

            case CommunicationsModule.DIGITAL_MARKETINGS_MODULE_CODE:
            case CommunicationsModule.DIGITAL_MARKETINGS_ROUTE:
                return ideLinkTheme.digitalMarketings;
            case CommunicationsModule.PARTNERSHIPS_MODULE_CODE:
            case CommunicationsModule.PARTNERSHIPS_ROUTE:
                return ideLinkTheme.partnerships;
            case CommunicationsModule.LEAFLETS_MODULE_CODE:
            case CommunicationsModule.LEAFLETS_ROUTE:
                return ideLinkTheme.leaflets;

            case EventsModule.EVENTS_MODULE_CODE:
                return ideLinkTheme.events;
            case SurveysModule.SURVEYS_MODULE_CODE:
                return ideLinkTheme.surveys;
            case GondolesModule.GONDOLES_MODULE_CODE:
                return ideLinkTheme.gondoles;

            case AUDITS_MODULE_CODE:
                return ideLinkTheme.audits;
            case ACTION_PLANS_MODULE_CODE:
                return ideLinkTheme.actionPlans;
            default:
                return ideLinkTheme.offers;
        }
    };
}
