import './AuditsTheme.scss';

import { IdelinkModuleTheme } from '../../../xlink/configs/IdeLinkTheme';
import feedImage from '../assets/default-img-audits-square.svg';
import iconWhite from '../assets/ic-audits-white.svg';
import iconColor from '../assets/ic-audits.svg';

export const AuditsTheme: IdelinkModuleTheme = {
    className: 'idelink-theme il-theme-audits',
    backgroundColor: '#FFB800',
    feedImage,
    iconColor,
    iconWhite,
};
