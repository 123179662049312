import { getModuleName } from '../../../configs/ModuleNamesMap';
import { CommunicationsModule } from '../../Communications/CommunicationsModule';
import { EventsModule } from '../../Events/EventsModule';
import { GondolesModule } from '../../Gondoles/GondolesModule';
import { OffersModule } from '../../Offers/OffersModule';
import { OperationsModule } from '../../Operations/OperationsModule';
import { SurveysModule } from '../../Surveys/SurveysModule';

export const VIDEO_MODULE_CODE = 'Videos';
export const NEWS_MODULE_CODE = 'News';
export const AUDITS_MODULE_CODE = 'Audits';
export const ACTION_PLANS_MODULE_CODE = 'ActionPlans';

export class DashboardHelper {
    public static getBaseRoute = (moduleName: string) => {
        switch (moduleName) {
            case OffersModule.OFFERS_MODULE_CODE:
                return OffersModule.PACKAGED_ORDER_LIST();

            case OperationsModule.OPERATIONS_MODULE_CODE:
                return OperationsModule.OPERATION_LIST;
            case OperationsModule.ENGAGEMENT_MODULE_CODE:
                return OperationsModule.ENGAGEMENT_BASE;

            case CommunicationsModule.COMMUNICATION_GROUP_CODE:
            case CommunicationsModule.COMMUNICATION_GROUP_ITEMS_CODE:
                return CommunicationsModule.COMMUNICATION_LIST;

            case CommunicationsModule.DIGITAL_MARKETINGS_MODULE_CODE:
                return CommunicationsModule.DIGITAL_MARKETING_BASE;
            case CommunicationsModule.PARTNERSHIPS_MODULE_CODE:
                return CommunicationsModule.PARTNERSHIP_BASE;
            case CommunicationsModule.LEAFLETS_MODULE_CODE:
                return CommunicationsModule.LEAFLET_BASE;

            case EventsModule.EVENTS_MODULE_CODE:
                return EventsModule.EVENT_LIST_ROUTE;
            case SurveysModule.SURVEYS_MODULE_CODE:
                return SurveysModule.SURVEY_LIST_ROUTE;
            case GondolesModule.GONDOLES_MODULE_CODE:
                return GondolesModule.GONDOLE_LIST;

            default:
                return `/${moduleName}`.toLowerCase();
        }
    };

    public static getTodoRoute = (moduleName: string, items: Array<{ id: string; sub_module: string }> = []) => {
        if (items.length > 1 || items.length === 0) {
            return DashboardHelper.getBaseRoute(moduleName);
        }
        const firstItem = items[0];
        if (firstItem.sub_module) {
            const submoduleName = getModuleName(firstItem.sub_module);
            return `${DashboardHelper.getBaseRoute(submoduleName)}/${firstItem.id}`;
        }
        return `${DashboardHelper.getBaseRoute(moduleName)}/${firstItem.id}`;
    };

    public static isModuleHandled(moduleName: string) {
        switch (moduleName) {
            case VIDEO_MODULE_CODE:
            case NEWS_MODULE_CODE:
            case AUDITS_MODULE_CODE:
            case ACTION_PLANS_MODULE_CODE:
                return false;
            default:
                return true;
        }
    }

    public static getContentUnavailableMessageId(moduleName: string): string {
        switch (moduleName) {
            case VIDEO_MODULE_CODE:
                return 'commons.module.videos.content.unavailable';
            case NEWS_MODULE_CODE:
                return 'commons.module.news.content.unavailable';
            case AUDITS_MODULE_CODE:
                return 'commons.module.audits.content.unavailable';
            case ACTION_PLANS_MODULE_CODE:
                return 'commons.module.actionPlans.content.unavailable';
            default:
                return `all content for ${moduleName} are not available`;
        }
    }
}
