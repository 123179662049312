import { formatDateCustom, formatDateShort } from '../../lib/IdeLink.Web.Common/src/utils/dateUtils';

const toTotalPrice = (rawTotalPrice: string): string => {
    let totalPrice: number;
    try {
        totalPrice = Number.parseFloat(rawTotalPrice);
    } catch (e) {
        totalPrice = 0;
    }
    return totalPrice.toFixed(2);
}

export const frFeedFuncs = {
    //* AUDITS
    Feed_Audits_FEED_AUDIT_AUTO_AUDIT_CREATED: () => "La check-list a été créée avec succès, n’oubliez pas de la terminer.",
    Feed_Audits_FEED_AUDIT_DONE: () => "La check-list est terminée, bravo !",
    Feed_Audits_FEED_AUDIT_SHOP_APPROVAL_PENDING: () => "Votre animateur attend votre validation ;)",
    Feed_Audits_FEED_AUTO_CHECK_CREATED: () => "Un auto-check vous a été envoyé. Prenez quelques minutes pour vous auto-évaluer !",
    Feed_Audits_FEED_REMINDER_EVENT: ({ RemainingDays }: any) => `Plus que ${RemainingDays} jour(s) pour répondre à l’auto-check, après il sera trop tard.`,

    //todo rename Feed_PackagedOrders_FEED_PUBLISH_EVENT to Feed_Offer_FEED_PUBLISH_EVENT
    //todo after rename OfferModuleCode by Offers instead of PackagedOrders
    Feed_PackagedOrders_FEED_PUBLISH_EVENT: () => 'Une affaire à faire, un nouveau bon plan vient d’arriver !',
    Feed_PackagedOrders_FEED_ORDER_EVENT: ({ TotalPrice }: any) => `Tada ! Votre commande de ${toTotalPrice(TotalPrice)} € est dans les tuyaux.`,
    Feed_PackagedOrders_FEED_REMINDER_EVENT: () => 'Vous n’avez toujours pas consulté ce bon plan, il mérite bien un petit coup d’œil !',
    Feed_PackagedOrders_FEED_SOLD_OUT_EVENT: () => 'Trop tard, le stock est épuisé ! Il faudra être plus à l’affût la prochaine fois.',

    //* GOOD DEALS
    Feed_GoodDeals_FEED_SOLD_OUT_ORDERED_EVENT: () => 'C’est terminé, le stock est épuisé ! Vous avez bien fait d’en profiter !',
    Feed_GoodDeals_FEED_PUBLISH_EVENT: () => 'Une affaire à faire, une nouvelle offre vient d’arriver !',
    Feed_GoodDeals_FEED_REMINDER_EVENT: () => 'Vous n’avez toujours pas consulté cette offre, elle mérite bien un petit coup d’œil !',
    Feed_GoodDeals_FEED_ORDER_EVENT: ({ TotalPrice }: any) => `Tada ! Votre commande de ${toTotalPrice(TotalPrice)} € est dans les tuyaux.`,
    Feed_GoodDeals_FEED_INCREMENTAL_SAVE_EVENT: () => 'Nous avons enregistré votre panier.',
    Feed_GoodDeals_FEED_INVALIDATE_ORDER_EVENT: ({ RemainingDays }: any) => `On hésite ? Vous avez annulé votre commande, vous avez ${RemainingDays} jours pour la resoumettre`,
    Feed_GoodDeals_FEED_INVALIDATE_ORDER_LAST_DAY_EVENT: ({ RemainingHours }: any) => `On hésite ? Vous avez annulé votre commande, vous avez ${RemainingHours} heures pour la resoumettre`,
    Feed_GoodDeals_FEED_INVALIDATE_ORDER_LAST_HOUR_EVENT: () => 'On hésite ? Vous avez annulé votre commande, vous n’avez plus que quelques minutes pour la resoumettre',

    //* OPERATIONS
    Feed_Operations_FEED_SOLD_OUT_ORDERED_EVENT: () => 'C’est terminé, le stock est épuisé ! Vous avez bien fait d’en profiter !',
    Feed_Operations_FEED_PUBLISH_EVENT: () => 'Une affaire à faire, une nouvelle offre vient d’arriver !',
    Feed_Operations_FEED_REMINDER_EVENT: () => 'Vous n’avez toujours pas consulté cette offre, elle mérite bien un petit coup d’œil !',
    Feed_Operations_FEED_ORDER_EVENT: ({ TotalPrice }: any) => `Tada ! Votre commande de ${toTotalPrice(TotalPrice)} € est dans les tuyaux.`,
    Feed_Operations_FEED_INCREMENTAL_SAVE_EVENT: () => 'Nous avons enregistré votre panier.',
    Feed_Operations_FEED_INVALIDATE_ORDER_EVENT: ({ RemainingDays }: any) => `On hésite ? Vous avez annulé votre commande, vous avez ${RemainingDays} jours pour la resoumettre`,
    Feed_Operations_FEED_INVALIDATE_ORDER_LAST_DAY_EVENT: ({ RemainingHours }: any) => `On hésite ? Vous avez annulé votre commande, vous avez ${RemainingHours} heures pour la resoumettre`,
    Feed_Operations_FEED_INVALIDATE_ORDER_LAST_HOUR_EVENT: () => 'On hésite ? Vous avez annulé votre commande, vous n’avez plus que quelques minutes pour la resoumettre',

    //? SURVEYS
    Feed_Surveys_FEED_PUBLISH_EVENT: ({ EndDate }: any) => `On a besoin de votre avis. Un nouveau sondage est publié. Clôture des réponses le ${formatDateCustom(EndDate, 'dd/MM/yyyy', '-')}.`,
    Feed_Surveys_FEED_PUBLISH_LAST_DAY_EVENT: () => 'On a besoin de votre avis. Un nouveau sondage est publié. Clôture des réponses dans quelques heures.',
    Feed_Surveys_FEED_REMINDER_SENT_EVENT: ({ RemainingDays = 0 }: any) => `Plus que ${RemainingDays} jours pour donner votre avis, après il sera trop tard.`,
    Feed_Surveys_FEED_REMINDER_LAST_DAY_SENT_EVENT: () => 'Plus que quelques heures pour donner votre avis, après il sera trop tard.',
    Feed_Surveys_FEED_TOO_LATE_EVENT: () => "C'est dommage, vous n'avez pas répondu à temps. On fera sans votre avis mais on compte sur vous la prochaine fois.",
    Feed_Surveys_FEED_REPLY_VALIDATED_EVENT: () => 'Good job ! Nous avons bien reçu votre réponse.',
    Feed_Surveys_FEED_REPLY_INVALIDATED_EVENT: ({ RemainingDays = 0 }: any) => `Un moment de doute ? Vous avez annulé votre réponse, vous avez ${RemainingDays} jours pour valider votre réponse.`,
    Feed_Surveys_FEED_REPLY_INVALIDATED_LAST_DAY_EVENT: () => "Un moment de doute ? Vous avez annulé votre réponse, vous n'avez plus que quelques heures pour valider votre réponse.",
    Feed_Surveys_FEED_REPLY_SAVED_EVENT: () => 'Nous avons enregistré vos premières réponses. Encore un petit effort, vous être proche du but.',
    Feed_Surveys_FEED_POSTPONE_EVENT: ({ RemainingDays = 0 }: any) => `Un peu de répit, la date d’échéance a changé, il vous reste ${RemainingDays} jours.`,
    Feed_Surveys_FEED_POSTPONE_LAST_DAY_EVENT: () => 'Un peu de répit, la date d’échéance a changé, il vous reste quelques heures.',
    Feed_Surveys_FEED_PREPONE_EVENT: ({ RemainingDays = 0 }: any) => `Tout s’accélère, la date d’échéance a changé, il ne vous reste plus que ${RemainingDays} jours pour répondre`,
    Feed_Surveys_FEED_PREPONE_LAST_DAY_EVENT: () => 'Tout s’accélère, la date d’échéance a changé, il ne vous reste plus que quelques heures pour répondre',

    //* EVENTS
    Feed_Events_FEED_PUBLISH_EVENT: ({ RemainingDays = 0 }: any) => `À vos calendriers, vous avez reçu une nouvelle invitation. Vous avez ${RemainingDays} jours pour nous confirmer votre participation.`,
    Feed_Events_FEED_PUBLISH_LAST_DAY_EVENT: () => 'À vos calendriers, vous avez reçu une nouvelle invitation. Vous avez quelques heures pour nous confirmer votre participation.',
    Feed_Events_FEED_REMINDER_SENT_EVENT: ({ RemainingDays = 0 }: any) => `Plus que ${RemainingDays} jours pour confirmer votre participation. Après il sera trop tard.`,
    Feed_Events_FEED_REMINDER_LAST_DAY_SENT_EVENT: () => 'Plus que quelques heures pour confirmer votre participation. Après il sera trop tard.',
    Feed_Events_FEED_POSTPONE_EVENT: ({ RemainingDays = 0 }: any) => `Un peu de répit, la date d’échéance a changé, il vous reste ${RemainingDays} jours.`,
    Feed_Events_FEED_POSTPONE_LAST_DAY_EVENT: () => 'Un peu de répit, la date d’échéance a changé, il vous reste quelques heures.',
    Feed_Events_FEED_PREPONE_EVENT: ({ RemainingDays = 0 }: any) => `Tout s'accélère, la date d'échéance a changé, il ne vous reste plus que ${RemainingDays} jours pour répondre.`,
    Feed_Events_FEED_PREPONE_LAST_DAY_EVENT: () => "Tout s'accélère, la date d'échéance a changé, il ne vous reste plus que quelques heures pour répondre.",
    Feed_Events_FEED_TOO_LATE_EVENT: () => 'Vous n’avez pas donné votre réponse à temps. Tant pis, on se verra une prochaine fois.',
    Feed_Events_FEED_REPLY_VALIDATED_EVENT: () => 'Vous avez confirmé votre participation. On sera content de vous compter parmi nous.',
    Feed_Events_FEED_REPLY_INVALIDATED_EVENT: ({ RemainingDays = 0 }: any) => `On change d’avis ? Vous avez annulé votre réponse, vous avez ${RemainingDays} jours pour la soumettre de nouveau.`,
    Feed_Events_FEED_REPLY_INVALIDATED_LAST_DAY_EVENT: () => "On change d’avis ? Vous avez annulé votre réponse, vous n'avez plus que quelques heures pour la soumettre de nouveau.",
    Feed_Events_FEED_REPLY_SAVED_EVENT: () => 'Nous avons enregistré vos premières réponses. Il ne faut pas s’arrêter en si bon chemin, on attend votre validation.',
    Feed_Events_FEED_START_TODAY_EVENT: ({ Title }: any) => `On y est. Aujourd’hui démarre l’évènement ${Title}`,
    Feed_Events_FEED_START_TOMORROW_EVENT: ({ EventStartDate, Address }: any) => `Demain, c’est le grand jour ! Cet évènement aura lieu à ${formatDateCustom(EventStartDate, 'HH:mm', '-')} à ${Address}.`,
    Feed_Events_FEED_START_TOMORROW_NO_LOCATION_EVENT: ({ EventStartDate }: any) => `Demain, c’est le grand jour ! Cet évènement aura lieu à ${formatDateCustom(EventStartDate, 'HH:mm', '-')}.`,
    Feed_Reminders_FEED_REMINDER_STARTED: () => "On y est, l'évènement démarre aujourd'hui !",

    //? OFFERS
    Feed_Offers_FEED_SOLD_OUT_ORDERED_EVENT: () => 'C’est terminé, le stock est épuisé ! Vous avez bien fait d’en profiter !',
    Feed_Offers_FEED_PUBLISH_EVENT: () => 'Une affaire à faire, une nouvelle offre vient d’arriver !',
    Feed_Offers_FEED_REMINDER_EVENT: () => 'Vous n’avez toujours pas consulté cette offre, elle mérite bien un petit coup d’œil !',
    Feed_Offers_FEED_ORDER_EVENT: ({ TotalPrice }: any) => `Tada ! Votre commande de ${toTotalPrice(TotalPrice)} € est dans les tuyaux.`,
    Feed_Offers_FEED_INCREMENTAL_SAVE_EVENT: () => 'Nous avons enregistré votre panier.',
    Feed_Offers_FEED_INVALIDATE_ORDER_EVENT: ({ RemainingDays }: any) => `On hésite ? Vous avez annulé votre commande, vous avez ${RemainingDays} jours pour la resoumettre`,
    Feed_Offers_FEED_INVALIDATE_ORDER_LAST_DAY_EVENT: ({ RemainingHours }: any) => `On hésite ? Vous avez annulé votre commande, vous avez ${RemainingHours} heures pour la resoumettre`,
    Feed_Offers_FEED_INVALIDATE_ORDER_LAST_HOUR_EVENT: () => 'On hésite ? Vous avez annulé votre commande, vous n’avez plus que quelques minutes pour la resoumettre',
    Feed_News_FEED_PUBLISH_EVENT: () => 'Une nouvelle actualité est disponible.',
    Feed_News_FEED_REMINDER_EVENT: () => "Vous n'avez toujours pas consulté cette actualité.",
    Feed_News_FEED_LIKED_EVENT: () => "Ça c'est une news intéressante. Vous avez liké cette actualité.",

    //? COMMS > DIGITAL
    Feed_DigitalMarketings_FEED_PUBLISH_EVENT: ({ EndDate }: any) => `Une nouvelle opération communication digitale est disponible. Vous avez jusqu'au ${formatDateShort(EndDate)} pour donner votre réponse.`,
    Feed_DigitalMarketings_FEED_REMINDER_EVENT: ({ RemainingDays = 0 }: any) => `Il vous reste ${RemainingDays} pour donner votre réponse à cette opération communication digitale.`,
    Feed_DigitalMarketings_FEED_REPLIED_POSITIVE_EVENT: ({ TotalPrice }: any) => `Vous avez accepté la participation à cette opération communication digitale pour un montant de ${toTotalPrice(TotalPrice)} €.`,
    Feed_DigitalMarketings_FEED_REPLIED_NEGATIVE_EVENT: () => "Vous n'êtes pas intéressé par cette opération communication digitale, votre réponse a été prise en compte.",
    Feed_DigitalMarketings_FEED_INVALIDATE_EVENT: ({ RemainingDays = 0 }: any) => `Un moment de doute ? Vous avez annulé votre réponse, vous avez ${RemainingDays} jours pour valider votre réponse.`,
    Feed_DigitalMarketings_FEED_INVALIDATE_LAST_DAY_EVENT: () => "Un moment de doute ? Vous avez annulé votre réponse, vous n'avez plus que quelques heures pour valider votre réponse.",

    //* COMMS > PARTNERSHIP
    Feed_Partnerships_FEED_PUBLISH_EVENT: ({ EndDate }: any) => `Une nouvelle opération partenariat est disponible. Vous avez jusqu'au ${formatDateShort(EndDate, '-')} pour donner votre réponse.`,
    Feed_Partnerships_FEED_REMINDER_EVENT: ({ RemainingDays = 0 }: any) => `Il vous reste ${RemainingDays} pour donner votre réponse à cette opération partenariat.`,
    Feed_Partnerships_FEED_REPLIED_POSITIVE_EVENT: ({ TotalPrice }: any) => `Vous avez accepté la participation à cette opération partenariat pour un montant de ${toTotalPrice(TotalPrice)} €.`,
    Feed_Partnerships_FEED_REPLIED_NEGATIVE_EVENT: () => "Vous n'êtes pas intéressé par cette opération partenariat, votre réponse a été prise en compte.",
    Feed_Partnerships_FEED_INVALIDATE_EVENT: ({ RemainingDays = 0 }: any) => `Un moment de doute ? Vous avez annulé votre réponse, vous avez ${RemainingDays} jours pour valider votre réponse.`,
    Feed_Partnerships_FEED_INVALIDATE_LAST_DAY_EVENT: () => "Un moment de doute ? Vous avez annulé votre réponse, vous n'avez plus que quelques heures pour valider votre réponse.",

    //! COMMS > LEAFLET
    Feed_Leaflets_FEED_PUBLISH_EVENT: ({ EndDate }: any) => `Une nouvelle commande de prospectus est disponible. Vous avez jusqu'au ${formatDateShort(EndDate, '-')} pour donner votre réponse.`,
    Feed_Leaflets_FEED_REMINDER_EVENT: ({ RemainingDays = 0 }: any) => `Il vous reste ${RemainingDays} pour donner votre réponse à cette commande de prospectus.`,
    Feed_Leaflets_FEED_REPLIED_POSITIVE_EVENT: ({ TotalPrice }: any) => `Vous avez accepté la participation à cette commande de prospectus pour un montant de ${toTotalPrice(TotalPrice)} €.`,
    Feed_Leaflets_FEED_REPLIED_NEGATIVE_EVENT: () => "Vous n'êtes pas intéressé par cette commande de prospectus, votre réponse a été prise en compte.",
    Feed_Leaflets_FEED_INVALIDATE_EVENT: ({ RemainingDays = 0 }: any) => `Un moment de doute ? Vous avez annulé votre réponse, vous avez ${RemainingDays} jours pour valider votre réponse.`,
    Feed_Leaflets_FEED_INVALIDATE_LAST_DAY_EVENT: () => "Un moment de doute ? Vous avez annulé votre réponse, vous n'avez plus que quelques heures pour valider votre réponse.",

    //! VIDEOS
    Feed_Videos_FEED_PUBLISH_EVENT: () => 'Une nouvelle vidéo a été publiée. Les nominés sont...',
    Feed_Videos_FEED_REMINDER_EVENT: () => 'Vous n’avez toujours pas regardé cette vidéo, elle se sent bien seule.',

    //! Action Plans
    Feed_ActionPlans_FEED_ACTION_PLAN_CREATED: () => "Un nouveau plan d'action vous a été affecté.",
    Feed_ActionPlans_FEED_ACTION_PLAN_CREATED_ASSIGNEE: ({AssignedToFirstName, AssignedToLastName}: any) => `Un nouveau plan d'action a été affecté à ${AssignedToFirstName} ${AssignedToLastName}.`,
    Feed_ActionPlans_FEED_ACTION_PLAN_TRANSFER: () => `Le plan d'action vous a été affecté.`,
    Feed_ActionPlans_FEED_ACTION_PLAN_TRANSFER_ASSIGNEE: ({AssignedToFirstName, AssignedToLastName}: any) => `Le plan d'action a été affecté à ${AssignedToFirstName} ${AssignedToLastName}.`,
    Feed_ActionPlans_FEED_ACTION_PLAN_CLOSED: () => `Vous avez terminé le plan d'action.`,
    Feed_ActionPlans_FEED_ACTION_PLAN_CLOSED_INITIATOR: ({InitiatorFirstName, InitiatorLastName}: any) => `Le plan d'action a été terminé par ${InitiatorFirstName} ${InitiatorLastName}.`,
    Feed_ActionPlans_FEED_ACTION_PLAN_DROPPED: () => `Vous avez abandonné le plan d'action.`,
    Feed_ActionPlans_FEED_ACTION_PLAN_DROPPED_INITIATOR: ({InitiatorFirstName, InitiatorLastName}: any) => `Le plan d'action a été abandonné par ${InitiatorFirstName} ${InitiatorLastName}.`,
    Feed_ActionPlans_FEED_ACTION_PLAN_NOTIFY_INITIATOR: ({InitiatorFirstName, InitiatorLastName}: any) => `${InitiatorFirstName} ${InitiatorLastName} vous a envoyé une relance concernant ce plan d'action.`,
    Feed_ActionPlans_FEED_ACTION_PLAN_NOTIFY_ASSIGNEE_AND_INITIATOR: ({InitiatorFirstName, InitiatorLastName, AssignedToFirstName, AssignedToLastName}: any) => `${InitiatorFirstName} ${InitiatorLastName} a envoyé une relance à ${AssignedToFirstName} ${AssignedToLastName} concernant ce plan d'action.`,
    Feed_ActionPlans_FEED_ACTION_PLAN_NOTIFY_ASSIGNEE: ({AssignedToFirstName, AssignedToLastName}: any) => `Vous avez envoyé une relance à ${AssignedToFirstName} ${AssignedToLastName} concernant ce plan d'action.`,
    Feed_ActionPlans_FEED_ACTION_PLAN_OPEN: () => `Vous avez rouvert le plan d'action.`,
    Feed_ActionPlans_FEED_ACTION_PLAN_OPEN_INITIATOR: ({InitiatorFirstName, InitiatorLastName}: any) => `${InitiatorFirstName} ${InitiatorLastName} a rouvert le plan d'action.`,
};
